import React from 'react';
import { Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';

import {
  TextField,
  Paper,
  Grid,
  FormHelperText,
  CircularProgress,
  Button
} from '@material-ui/core';

import { IAuthState, login as loginAction } from '../store/auth';
import { ILoginPayload } from '../store/auth';
import { IReduxState } from '../store/reducers';
import LoginSchema from '../utils/schemas/LoginSchema';
import RequireGuest from '../components/shared/RequireGuest';
import { development } from '../utils/deviceDetection';
import { Container, GridLeft, Wrapper } from '../styles/pages/login.style';

interface IProps extends IAuthState {
  login: (payload: ILoginPayload) => Promise<void>;
}

interface IFormValues {
  email: string;
  password: string;
}

const Login = (props: IProps) => {
  const Logo = require(`../assets/img/nuli/logo.png`);
  const Background = require(`../assets/img/nuli/background.jpg`);

  function login(values: IFormValues) {
    props.login(values);
  }

  const initialValues = development
    ? { email: 'admin1@user.io', password: 'azertyuiop' }
    : { email: '', password: '' };
  return (
    <Container container>
      <GridLeft backgroundImage={Background} item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Wrapper>
          <img src={Logo} />
          <Formik
            onSubmit={login}
            initialValues={initialValues}
            validationSchema={LoginSchema}
            render={(innerProps: FormikProps<IFormValues>) => {
              const { errors, handleSubmit, handleChange, handleBlur } = innerProps;
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id={'email'}
                    label={'Email address'}
                    name={'email'}
                    autoComplete={'email'}
                    error={!!errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                  {errors.email && (
                    <FormHelperText error id={'email'}>
                      {errors.email}
                    </FormHelperText>
                  )}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id={'password'}
                    label={'Password'}
                    name={'password'}
                    autoComplete={'password'}
                    type={'password'}
                    error={!!errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && (
                    <FormHelperText error id={'password'}>
                      {errors.password}
                    </FormHelperText>
                  )}
                  {props.message && <FormHelperText>{props.message}</FormHelperText>}
                  {props.loading && <CircularProgress />}
                  <Button type="submit" fullWidth variant="contained" color="primary">
                    Sign in
                  </Button>
                </form>
              );
            }}
          />
        </Wrapper>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => state.auth;

export default RequireGuest(connect(mapStateToProps, { login: loginAction })(Login));
